import * as React from "react"

import '../css/style.css';


const IndexPage = () => {
  return (
    <div>
      <title>Home Page</title>
      <div className="vertical-center">
        <div className="container-fluid">
          <div className="row">
            <div className="col">&nbsp;</div>
            <div className=" col col-md-auto">&nbsp;</div>
            <div className="col col-md-4">
              <div className="landing-block">
                <div className="display-1">Autheron</div>
                <div className="display-6">Coming Soon</div>
              </div>
            </div>
          </div> {/* row */}
          <div className="container-fluid fixed-bottom text-end">
            <footer><span title="{{buildinfo}}">&#960;</span></footer>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
